<template>
    <b-dropdown
        position="is-bottom-left"
        append-to-body
        aria-role="menu"
        @active-change="open"
    >
        <template #trigger>
            <a class="navbar-item" role="button">
                <b-tooltip
                    label="Notificaciones"
                    type="is-dark"
                    position="is-bottom"
                >
                    <a>
                        <b-icon type="is-primary" icon="bell-outline" />
                        <span
                            v-if="no_viewed_notifications > 0"
                            class="badge is-link"
                        >
                            {{ no_viewed_notifications }}
                        </span>
                    </a>
                </b-tooltip>
            </a>
        </template>

        <b-dropdown-item
            aria-role="menu-item"
            :focusable="false"
            custom
            paddingless
        >
            <div class="notifications-panel">
                <header class="notifications-panel__header">
                    <p>Notificaciones</p>
                </header>

                <div class="notifications-panel__content">
                    <b-loading v-model="loading" :is-full-page="false" />
                    <div
                        v-if="notifications.length === 0"
                        class="is-flex is-align-items-center is-justify-content-center"
                        style="height: 100%; padding-top: 1rem; padding-bottom: 1rem;"
                    >
                        <div class="level-item has-text-centered">
                            <div>
                                <p class="heading has-text-primary">
                                    No hay notificaciones
                                </p>
                                <p class="title">
                                    <b-icon
                                        size="is-large"
                                        icon="message-bulleted-off"
                                        type="is-primary"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="notifications-list">
                        <router-link
                            v-for="notification in notifications"
                            :key="notification.id"
                            class="notifications-list__item"
                            :class="
                                !notification.viewed
                                    ? 'has-background-primary-light'
                                    : ''
                            "
                            :to="
                                `/notificaciones?notificacion_id=${notification.id}`
                            "
                        >
                            <div class="notification-content">
                                <p>
                                    {{ notification.message }}
                                </p>
                                <small
                                    :class="
                                        notification.viewed
                                            ? 'has-text-grey'
                                            : 'has-text-primary'
                                    "
                                >
                                    {{ notification.created_at | moment }}
                                </small>
                            </div>
                            <div class="notification-mark">
                                <b-icon
                                    v-if="!notification.viewed"
                                    type="is-primary"
                                    icon="circle-medium"
                                />
                            </div>
                        </router-link>
                    </div>
                </div>

                <footer class="notifications-panel__footer">
                    <router-link to="/notificaciones">
                        Ver todo
                    </router-link>
                </footer>
            </div>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import moment from 'moment';

// Config
moment.locale('es');

export default {
    name: 'NotificationsBell',
    data() {
        return {
            loading: false,
            first_fetch: true,
            notifications: [],
            no_viewed_notifications: 2
        };
    },
    methods: {
        open(active) {
            //if (active && this.first_fetch) {
            if (active && this.first_fetch) {
                this.first_fetch = false;
                this.fetchNotifications();
            }
        },
        fetchNotifications() {
            this.loading = true;
            this.notifications = [];
            setTimeout(() => {
                this.notifications = [
                    {
                        id: 3,
                        created_at: '2021-11-22 11:00:00',
                        message:
                            'Este es un mensaje de prueba 2, no le preste mas atencion de la debida',
                        viewed: false,
                        perfil: {}
                    },
                    {
                        id: 2,
                        created_at: '2021-11-22 10:00:00',
                        message:
                            'Este es un mensaje de prueba 2, no le preste mas atencion de la debida',
                        viewed: false,
                        perfil: {}
                    },
                    {
                        id: 1,
                        created_at: '2021-11-22 09:00:00',
                        message:
                            'Este es un mensaje de prueba 1, no le preste mas atencion de la debida',
                        viewed: true,
                        perfil: {}
                    }
                ];
                this.loading = false;
            }, 1500);
        }
    },
    filters: {
        moment: function(value) {
            return moment(value).fromNow();
        }
    }
};
</script>

<style lang="scss" scoped>
.dropdown-content {
    padding-bottom: 0 !important;
}

.notifications-panel {
    min-width: 350px;

    &__header {
        padding: 0 0.75rem 0.5rem 0.75rem;
        border-bottom: 1px solid #ededed;

        &:first-child {
            font-weight: 700;
        }
    }

    &__content {
        position: relative;
    }

    &__footer {
        padding: 0.5rem 0.75rem 0 0.75rem;
        border-top: 1px solid #ededed;
        text-align: center;
    }
}

.notifications-list {
    &__item {
        padding: 0.9rem 0.75rem;
        border-bottom: 1px solid #e7e7e7;
        background-color: #fafafa;
        display: flex;
        align-items: center;
    }
}
</style>
